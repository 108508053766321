import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Start from '../views/secure/Start.vue';
import AllVideos from '../views/secure/AllVideos.vue';
import WatchVideo from '../views/secure/WatchVideo.vue';
import WatchMyVideo from '../views/secure/WatchMyVideo.vue';
import DesignWorksheet from '../views/secure/DesignWorksheet.vue';
import MyVideos from '../views/secure/MyVideos.vue';
import MyStops from '../views/secure/MyStops.vue';
import Assignments from '../views/secure/Assignments.vue';
import ChangePass from '../views/secure/ChangePass.vue';

import firebase from '@firebase/app';
import '@firebase/auth';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/start',
    name: 'start',
    component: Start,
    meta: { requiresAuth: true }
  },
  {
    path: '/myvideos',
    name: 'myvideos',
    component: MyVideos,
    meta: { requiresAuth: true }
  },
  {
    path: '/allvideos',
    name: 'allvideos',
    component: AllVideos,
    meta: { requiresAuth: true }
  },
  {
    path: '/watchvideo',
    name: 'watchvideo',
    component: WatchVideo,
    meta: { requiresAuth: true }
  },
  {
    path: '/watchmyvideo',
    name: 'watchmyvideo',
    component: WatchMyVideo,
    meta: { requiresAuth: true }
  },
  {
    path: '/designworksheet',
    name: 'designworksheet',
    component: DesignWorksheet,
    meta: { requiresAuth: true }
  },
  {
    path: '/mystops',
    name: 'mystops',
    component: MyStops,
    meta: { requiresAuth: true }
  },
  {
    path: '/assignments',
    name: 'assignments',
    component: Assignments,
    meta: { requiresAuth: true }
  },
  {
    path: '/changepass',
    name: 'changepass',
    component: ChangePass,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;

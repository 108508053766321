<template>
  <top-header></top-header>
  <div id="spacer"></div>
  <div id="content" v-if="dataReady">
    <ModalDrawing
      :img_url="aURL"
      :theTitle="aTitle"
      v-show="isModalVisible"
      @close="closeModal"
    />
    <ModalActivity
      :img_url="aURL"
      :theTitle="aTitle"
      v-show="isModalActivityVisible"
      @close="closeModalActivity"
    />
    <h2>My Stops</h2> 
    <div>
      <router-link
            :to="{
              name: 'designworksheet'
            }"
          >
          <n-button ghost type="primary" color="blue"
            >Create New Stop</n-button
          >
      </router-link>
    </div>      
    <div id="content_box" v-for="aMake in makes" :key="aMake.id" > 
      <div id="inner_text">  
        {{ aMake.date }}
      </div> 
      <div id="inner_text">  
        Place: {{ aMake.place }}
      </div> 
      <div id="inner_text" v-if="aMake.video">  
        <a
          id="modal_link"
          href="#"
          @click="showModalActivity(aMake.video, 'Video')"
        >
          Video
        </a>
      </div>
      <div id="inner_text" v-if="aMake.photo">  
        <a
          id="modal_link"
          href="#"
          @click="showModal(aMake.photo, 'Photo')"
        >
          Photo
        </a>
      </div>
      <div id="inner_text">
        <router-link
            :to="{
              name: 'designworksheet',
              query: {
                docID: aMake.id
              }
            }"
          >
          Design Worksheet
      </router-link>
      </div>
    </div>
    
  </div>
  <div id="content" v-else>
    Data loading, please wait...
  </div> 
</template>

<script>
import TopHeader from '@/components/Top-Header';
import firebase from '@firebase/app';
import { NButton } from 'naive-ui';
import ModalDrawing from '@/components/Modal-Drawing.vue';
import ModalActivity from '@/components/Modal-Activity.vue';

export default {
  name: 'MyStopsView',
  data() {
    return {
      error: '',
      dataReady: false,
      makes: [],
      isModalVisible: false,
      isModalActivityVisible: false,
      aURL: '',
      aTitle: ''
    }

  },
  components: {
    'top-header': TopHeader,
    'n-button': NButton,
    ModalDrawing,
    ModalActivity
  },
  methods: {
    showModal(theURL, theTitle) {
      this.isModalVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    showModalActivity(theURL, theTitle) {
      this.isModalActivityVisible = true;
      this.aURL = theURL;
      this.aTitle = theTitle;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeModalActivity() {
      this.isModalActivityVisible = false;
      this.aURL = '';
    },
    getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)
      ? match[2]
      : null;
    }
  },
  async mounted() {
    var db = firebase.firestore();
    try {
      var makeRef = await db.collection('makewalk');
      var theMakes = await makeRef
        .where('id', '==', this.$store.state.user.id);
      let snapshot = await theMakes.get();
      snapshot.forEach(doc => {
        this.makes.push({
          date: doc.data().date,
          place: doc.data().question1,
          video: 'https://www.youtube.com/embed/'+this.getId(doc.data().question6),
          photo: doc.data().question7,
          id: doc.id
        });
      }, this);  
    } catch (err) {
      console.log(err);
    }
    this.dataReady = true;
  }
};
</script>

<style scoped>
#spacer {
  height: 80px;
}

#content {
  text-align: left;
  margin: auto;
  padding-top: 20px;
  font-size: 18px;
  width: 50%;
}

.error {
  color: red;
  font-size: 18px;
  margin-bottom: 0px;
}

#content_box {
  text-align: left;
  float:left;
  margin: auto;
  margin-bottom: 30px;
  margin-right: 40px;
  font-size: 18px;
  width: 230px;
  border-width:2px;
  border-style:solid;
  border-color:black;
  border-radius: 25px;
  padding: 20px;
  background: rgb(255, 190, 190);
	background-clip: border-box;
}

#inner_text {
  margin-bottom: 10px;
}

h2 {
  text-align: center;
  font-size: 22px;
  padding: 0px;
  margin: 0px;
}

button {
  width: 150px;
  height: 35px;
  font-size: 18px;
  padding: 15px;
  margin: 20px;
}
</style>
